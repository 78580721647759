/* notification panel fixes */

.navbar .nav.navbar-nav>li.icon.dropdown.notify-dropdown .dropdown-menu {
    right: 0;
}

.navbar .ntf-filter-element .ntf-dropdown>.btn.ntf-dropdown-toggle>.caret {
    position: absolute;
    right: 10px;
    top: 11px;
    transform: rotateX(0deg);
}

.navbar .ntf-filter-element .ntf-dropdown.opened>.btn.ntf-dropdown-toggle>.caret {
    transform: rotateX(180deg);
}

.navbar .ntf-filters .ntf-filter-element.vismaicon.vismaicon-filter-reset {
    left: 0;
    top: 1px;
}

.navbar .ntf-wrapper.ntf-in-app .ntf-panel-group .ntf-panel-title>a:first-child>.vismaicon {
    top: 3px;
}

.navbar .ntf-wrapper .vismaicon.vismaicon-sm.vismaicon-notifications::before {
    background-position: 0 0;
}

.navbar .ntf-filters .ntf-filter-element.ntf-companies .vismaicon-autocomplete {
    top: 4px;
    left: auto;
}

.navbar .ntf-filters .ntf-filter-element.ntf-companies .vismaicon-autocomplete:before {
    background-position: 0 0;
}

.navbar .ntf-filters .ntf-filter-element.ntf-companies .disabled .vismaicon-autocomplete:before {
    background-position: -30px 0;
}

.navbar .ntf-panel .ntf-panel-title .ntf-trigger .ntf-more.vismaicon {
    top: 1px;
    left: auto;
}

.navbar .ntf-panel .ntf-panel-title .ntf-trigger .ntf-more.vismaicon::before {
    background-position: 0 0;
}

body {
    padding-top: 43px;
    min-width: 420px;
}

#page {
    min-height: 100%;
    position: relative;
    padding-bottom: 41px;
}

footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.admin-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.admin-container.bg-white {
    background-color: #fff;
}

.content-main {
    min-height: 300px;
    margin-top: 60px;
    -webkit-box-flex: 1;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-flex: 1;
    /* OLD - Firefox 19- */
    -webkit-flex: 1;
    /* Chrome */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    -webkit-box-ordinal-group: 0;
    /* OLD - iOS 6-, Safari 3.1-6 */
    -moz-box-ordinal-group: 0;
    /* OLD - Firefox 19- */
    -ms-flex-order: 0;
    /* TWEENER - IE 10 */
    -webkit-order: 0;
    /* NEW - Chrome */
    order: 0;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.admin-container.user-context .content-main {
    margin-top: 0;
}

.admin-loading-widget {
    background: #fff;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.admin-loading-widget>img {
    left: 50%;
    margin: -32px 0px 0px -32px;
    position: absolute;
    top: 50%;
}

.vismaicon-company::before {
    background-image: url(assets/images/icons/24_company.svg);
}

.vismaicon-company-group::before {
    background-image: url(assets/images/icons/24_company_groups.svg);
}

.vismaicon-add-user::before {
    background-image: url(assets/images/icons/24_add_user2.svg);
}

.vismaicon-import-user::before {
    background-image: url(assets/images/icons/24_import_user3.svg);
}

.vismaicon-remove-user::before {
    background-image: url(assets/images/icons/24_remove_user2.svg);
}

.vismaicon-update-roles::before {
    background-image: url(assets/images/icons/24_update_roles.svg);
}

.vismaicon-add-usergroup::before {
    background-image: url(assets/images/icons/24_add_usergroup2.svg);
}

.vismaicon-add-company::before {
    background-image: url(assets/images/icons/24_add_company2.svg);
}

.vismaicon-add-company-group::before {
    background-image: url(assets/images/icons/24_add_company_group2.svg);
}

.vismaicon-import-company::before {
    background-image: url(assets/images/icons/24_import_company3.svg);
}

.vismaicon-user-settings::before {
    background-image: url(assets/images/icons/24_user_settings.svg);
}

.nav-tabs:not(.navbar-nav)>li.active:not(.tabdrop)>a {
    pointer-events: initial;
}

.vismaicon>sub.items-count {
    position: absolute;
    top: 0;
    right: -7px;
}

.user-tabs-content {
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 30px;
}

.visma-net-back-button {
    position: absolute;
    right: 38px;
    top: 36px;
}

.visma-net-back-button span {
    vertical-align: middle;
    margin-right: 4px;
}

.admin-alert {
    position: absolute;
    width: 100%;
    z-index: 9000;
}

span.vismaicon.vismaicon-sm.vismaicon-delete, span.vismaicon.vismaicon-sm.vismaicon-info, span.vismaicon.vismaicon-sm.vismaicon-zoom-in, span.vismaicon.vismaicon-sm.vismaicon-cancel-circle {
    cursor: pointer
}

span.vismaicon.vismaicon-sm.vismaicon-cancel-circle.disabled {
    cursor: default;
}

.table-flex-wrapper {
    min-height: 400px;
    position: relative;
}

.btn+.btn {
    margin-left: 4px;
}

.form-group.readonly input[type="text"][disabled] {
    color: #282828;
}

/* nc tables */

.nc-table {
    font-family: inherit;
    position: relative;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.nc-table .p-scroller-content {
    will-change: auto;
}

.nc-table.p-datatable-flex-scrollable .p-scroller {
    height: 100% !important;
}

.nc-table table {
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed;
    width: 100%;
}

.nc-table th p>i {
    vertical-align: middle;
}

.nc-table thead>tr {
    background: #fcfcfc;
    background: linear-gradient(to bottom, #fcfcfc 0%, #f3f3f3 75%, #f9f9f9 100%);
}

.nc-table thead>tr>th, .nc-table tbody>tr>td, .nc-table tfoot>tr>td, .nc-table tbody>tr>th, .p-datatable-footer tr.table-summary td {
    background-color: transparent;
    border-style: solid dotted solid dotted;
    border-width: 1px;
    border-color: #ddd #e5e5e5 #d6d6d6 #e5e5e5;
    color: #282828;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    padding: 11px 10px 10px;
}

.nc-table thead>tr>th {
    color: #0974b3;
    padding-right: 16px;
    position: relative;
    white-space: nowrap;
}

.nc-table thead>tr>th:hover {
    color: #0974b3;
    background-color: transparent;
    border-style: solid dotted solid dotted;
    border-width: 1px;
    border-color: #ddd #e5e5e5 #d6d6d6 #e5e5e5;
}

.nc-table thead>tr>th:first-child, .nc-table tbody>tr>td:first-child, .nc-table tfoot>tr>td:first-child,
.nc-table tbody>tr>th:first-child, .p-datatable-footer tr.table-summary td:first-child {
    border-left-color: #ddd;
}

.nc-table thead>tr>th:last-child, .nc-table tbody>tr>td:last-child, .nc-table tfoot>tr>td:last-child,
.nc-table tbody>tr>th:last-child, .p-datatable-footer tr.table-summary td:last-child {
    border-right-color: #ddd;
}

.nc-table thead>.filters-row>th {
    padding: 5px 10px 4px 10px;
    border-top-width: 0px;
}

.nc-table thead>.filters-row .form-group {
    margin-bottom: 0;
}

.nc-table thead>.filters-row .form-group+.vismaicon-cancel-circle {
    position: absolute;
    top: 10px;
    right: 3px;
}

.nc-table.nc-table-condensed tbody>tr>.validation-info {
    height: auto;
}

.nc-table thead>.filters-row>th input, .nc-table thead>.filters-row>th .ui-dropdown, .nc-table .p-datatable-thead .nc-dropdown {
    width: 100%;
}

.nc-table thead>.filters-row>th:last-child input {
    width: calc(100% - 26px);
}

.nc-table thead>.filters-row>th .p-dropdown {
    width: calc(100% - 10px);
}

.nc-table thead>.filters-row>th:last-child .vismaicon-delete {
    position: absolute;
    right: 10px;
    top: 12px;
}

.nc-table .filters-row .ui-dropdown .ui-dropdown-label-container {
    width: 100%;
}

.nc-table .filters-row .ui-dropdown .ui-dropdown-label {
    border-radius: 15px;
}

.nc-table .vismaicon-cancel-circle.vismaicon-sm:before {
    background-image: url(assets/images/icons/16_cancel_new.svg);
}

.nc-table tbody {
    background: #fff;
}

.nc-table.nc-table-grey tbody {
    background: #f0f0f0;
}

.nc-table tbody>tr:nth-child(odd)>td, .nc-table tbody>tr:nth-child(odd)>th, .nc-table.nc-table-grey tbody>tr:nth-child(odd)>td, .nc-table.nc-table-grey tbody>tr:nth-child(odd)>th {
    background-color: #f5f5f5;
}

.nc-table tbody>tr {
    color: #282828;
}

.nc-table tfoot {
    text-align: left;
}

.p-datatable-footer table>tr, .p-datatable-footer tr.table-summary td {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 30px;
}

.nc-table tfoot>tr, .nc-table .p-datatable-footer table>tr, .p-datatable-footer tr.table-summary td {
    background-color: #f7f7f7;
    text-align: left;
}

.nc-table tfoot>tr td {
    height: 38px;
}

.p-datatable-footer tr.table-summary td {
    display: block;
    flex: 1 1 0;
    align-items: center;
    height: 30px;
}

.nc-table tbody>tr:not(.text-disabled):hover>td, .nc-table tbody>tr:not(.text-disabled):hover>th, .nc-table.nc-table-grey tbody>tr:not(.text-disabled):hover>td {
    background-color: #ccebff;
    color: #282828;
    cursor: default;
}

.nc-table tbody>tr[ng-reflect-data]:hover td, .nc-table tbody>tr.row-clickable:hover td, 
.nc-table tbody>tr.parent-row:hover td, .nc-table.nc-table-grey tbody>tr.parent-row:not(.text-disabled):hover>td {
    cursor: pointer;
}

.nc-table .parent-row .caret {
    margin-left: 7px;
}

.nc-table .parent-row.parent-expanded .caret {
    margin-left: 5px;
}

.nc-table tbody>tr>td {
    border-width: 0 1px 0 1px;
    height: 36px;
    padding: 10px 10px 9px;
}

.nc-table tbody>tr:first-child>td, .nc-table tbody>tr:first-child>th {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1) inset;
}

.nc-table .sort-header {
    height: 6px;
    position: absolute;
    right: 0;
}

.nc-table th .p-sortable-column-icon::before {
    background: url(assets/images/double-caret.svg) no-repeat 0 0;
    content: "";
    display: none;
    height: 11px;
    width: 9px;
    position: relative;
    top: -3px;
}

.nc-table th .pi-sort-amount-up-alt::before {
    background-position: 0 0;
    height: 6px;
    display: inline-block;
}

.nc-table th .pi-sort-amount-down::before {
    background-position: 0 -6px;
    height: 6px;
    display: inline-block;
}

.nc-table tbody>tr.p-highlight {
    position: relative;
}

.nc-table tbody>.row-selected td {
    background: #ebebeb;
    box-shadow: 0px 1px 0 #d7d7d7 inset, 0px -1px 0 #d7d7d7 inset;
}

.nc-table tbody>.row-selected th:first-child, .nc-table tbody>.row-selected td:first-child {
    position: relative;
}

.nc-table tbody>.row-selected td:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    width: 6px;
    height: 100%;
    background: #4d8400;
}

.nc-table tbody>.row-selected.disabled td {
    color: #a0a0a0;
}

th[class^="column-"] .vismaicon-cancel-circle {
    float: none !important;
    top: 8px;
}

.nc-table tbody>tr>.status-column {
    position: relative;
    padding-right: 24px;
    padding-left: 32px;
}

.status-column .vismaicon-cancel-circle {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
}

.status-column .vismaicon-filled {
    top: 50%;
    margin-top: -12px;
}

.nc-table .column-checkbox, .nc-table .column-expand-icon {
    width: 32px;
}

.nc-table .column-checkbox>input[type="checkbox"] {
    margin: 0 0 0 5px;
}

.nc-table .column-huge {
    width: 320px;
}

.nc-table .column-large {
    width: 220px;
}

.nc-table .column-medium {
    width: 160px;
}

.nc-table .column-small {
    width: 100px;
}

.nc-table .text-trim {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nc-table .status-list {
    display: table;
}

.nc-table .status-list>li {
    display: table-row;
}

.nc-table .status-list>li>span {
    display: table-cell;
    padding: 0 6px 0 5px;
}

.nc-table.nc-table-condensed tbody>tr>td {
    height: 27px;
}

.nc-table.nc-table-condensed tbody tr th, .nc-table.nc-table-condensed tfoot tr th, .nc-table.nc-table-condensed tfoot tr td,
.nc-table.nc-table-condensed thead tr th, .nc-table.nc-table-condensed tbody tr td, .nc-table .p-datatable-footer tr.table-summary td {
    padding: 7px 5px 6px;
    line-height: 100%;
}

.nc-table.nc-table-condensed thead .thead-dropdown th {
    padding: 10px 5px 7px;
}

.nc-table.nc-table-condensed thead .thead-dropdown .th-drop {
    padding: 2px 5px 2px;
}
.nc-table.nc-table-condensed thead .thead-dropdown .th-drop .nc-dropdown {
    margin-left: 0;
}

.nc-table.nc-table-condensed thead .filters-row th {
    padding: 2px 5px 2px;
}

.nc-table.nc-table-condensed thead .filters-row .status-column {
    min-width: 0;
}

.nc-table.nc-table-condensed thead .filters-row .column-expand-icon .vismaicon-cancel-circle {
    top: 8px;
    right: 4px;
}

.nc-table.nc-table-condensed tbody tr td span {
    line-height: 100%;
}
.nc-table .vertical-align > .vismaicon {
    margin-right: 0.4em;
}

/* expanded rows */

.nc-table tbody .row-expanded-header th {
    border-bottom: 0 none;
    font-weight: 600;
    white-space: nowrap;
}

.nc-table tbody .row-expanded-header .column-small {
    min-width: 0;
}

.nc-table.nc-table-grey tbody>tr.parent-expanded>td, .nc-table.nc-table-grey tbody>tr.row-expanded-header>th, .nc-table.nc-table-grey tbody>tr.row-expanded>td {
    background-color: #fff;
}

.nc-table.nc-table-grey tbody>tr.row-expanded>td {
    border-color: transparent;
    border-right-color: #ddd;
}

.nc-table.nc-table-grey tbody>tr.spacer>td {
    background-color: #fff;
    padding: 0;
    height: 16px;
}

.nc-table.nc-table-grey tbody>.row-expanded-header>th {
    box-shadow: 0px 1px 0 #d7d7d7 inset;
    border: 0 none;
}

.nc-table.nc-table-grey tbody>.row-expanded-header>th:first-child {
    border-left: 1px solid #ddd;
}

.nc-table.nc-table-grey tbody>.row-expanded-header>th:last-child {
    border-right: 1px solid #ddd;
}

.nc-table.nc-table-grey tbody>.spacer>td {
    box-shadow: 0px -1px 0 #d7d7d7 inset
}

.nc-table.nc-table-grey tbody>.row-expanded-header>th:first-child, .nc-table.nc-table-grey tbody>.row-expanded>td:first-child, .nc-table.nc-table-grey tbody>.spacer>td:first-child {
    position: relative;
}

.nc-table.nc-table-grey tbody>.row-expanded-header>th:first-child:before, .nc-table.nc-table-grey tbody>.row-expanded>td:first-child:before, .nc-table.nc-table-grey tbody>.spacer>td:first-child:before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    width: 6px;
    height: 100%;
    background: #4d8400;
}

.nc-table.nc-table-grey .vismaicon.vismaicon-info::before, .form-group.checkbox .vismaicon.vismaicon-info::before {
    background-image: url(assets/images/icons/16_info.svg);
}

.nc-table .flex-box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.nc-table .flex-box>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
}

.cdk-virtual-scroll-viewport {
    overflow-y: scroll;
}

/* nc paginator */

.nc-table .p-paginator {
    background: transparent;
    border: 0 none;
    display: inline-block;
    margin: 20px 0 0;
    padding: 0;
    text-align: left;
}

.nc-table .p-paginator .p-paginator-element, .nc-table .ui-paginator .p-paginator-pages {
    display: block;
    float: left;
    padding: 0;
}

.nc-table .p-paginator .p-paginator-element {
    display: block;
    margin: 0 0 0 4px;
    background: #f0f0f0;
    background: -webkit-linear-gradient(top, #ffffff 10%, #f0f0f0 75%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #ffffff), color-stop(75%, #f0f0f0));
    background: -o-linear-gradient(top, #ffffff 10%, #f0f0f0 75%);
    background: linear-gradient(to bottom, #ffffff 10%, #f0f0f0 75%);
    border-width: 1px;
    border-style: solid;
    border-color: #d1d1d1 #d1d1d1 #a5a5a5;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: #282828;
    cursor: pointer;
    line-height: 1.42857143;
    opacity: 1;
    height: 30px;
    width: 32px;
    padding: 7px 10px;
    position: relative;
}

.nc-table .p-paginator .p-paginator-element:hover {
    background: #e8f6ff;
    background: -webkit-linear-gradient(top, #e8f6ff 10%, #dae7ef 75%);
    background: linear-gradient(to bottom, #e8f6ff 10%, #dae7ef 75%);
    box-shadow: -1px -1px 0 #ffffff80 inset;
}

.nc-table .p-paginator .p-paginator-element.p-highlight {
    background: #3a6400;
    background: -webkit-linear-gradient(top, #3a6400 0, #406e00 6%, #497d00 100%);
    background: linear-gradient(to bottom, #3a6400 0, #406e00 6%, #497d00 100%);
    box-shadow: 0 1px 0 #fff3, inset 0 1px 0 #3a6400, inset 1px 0 0 #447500, inset -1px 0 0 #447500;
    border-width: 1px;
    border-color: #325700 #3b6500 #427000;
    color: #fff;
}

.nc-table .p-paginator .p-paginator-element.p-disabled {
    background: #d7d7d7;
    border-color: #d0d0d0;
    box-shadow: none;
    cursor: default;
}

.nc-table .p-paginator .p-paginator-first {
    margin-left: 0;
}

.nc-table .p-paginator .p-paginator-icon {
    display: block;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    height: 12px;
    width: 8px;
}

.nc-table .p-paginator .p-paginator-icon::before {
    background: url("assets/images/pagination-carets.svg") no-repeat 0 0;
    content: "";
    display: block;
    opacity: 1;
    height: 12px;
    width: 8px;
    position: absolute;
    left: 4px;
    top: 1px;
}

.nc-table .p-paginator .p-paginator-element.p-disabled .p-paginator-icon::before {
    opacity: .7;
}

.nc-table .p-paginator .p-paginator-icon.pi-angle-double-left::before {
    background-position: 0 0;
}

.nc-table .p-paginator .p-paginator-icon.pi-angle-left::before {
    background-position: 0 -36px;
}

.nc-table .p-paginator .p-paginator-icon.pi-angle-right::before {
    background-position: -18px -36px;
}

.nc-table .p-paginator .p-paginator-icon.pi-angle-double-right::before {
    background-position: -18px 0;
}

.nc-table .p-paginator .p-paginator-page {
    padding: 5px 0;
    text-align: center;
}

.nc-table .p-paginator .p-paginator-page.p-disabled {
    color: #777;
}

.nc-table td .vismaicon, .nc-table td .badge {
    margin-right: 4px;
}

/* nc-dpopdown */

.nc-table .ui-dropdown, .nc-table .ui-dropdown-hover, .nc-dropdown.p-dropdown, .nc-dropdown.p-dropdown-hover {
    background: transparent;
    border: 0 none;
    border-radius: 0;
    color: #282828;
    cursor: pointer;
    display: inline-flex;
    height: 30px;
    margin-left: 5px;
    min-width: auto;
    position: relative;
}

.nc-table .ui-dropdown.ui-state-focus, .nc-dropdown.p-focus {
    box-shadow: none;
}

.nc-table .ui-dropdown .ui-dropdown-label, .nc-dropdown.p-dropdown .p-dropdown-label {
    background: #f0f0f0;
    background: -webkit-linear-gradient(top, #ffffff 10%, #f0f0f0 75%);
    background: linear-gradient(to bottom, #ffffff 10%, #f0f0f0 75%);
    border-width: 1px;
    border-style: solid;
    border-color: #d1d1d1 #d1d1d1 #a5a5a5;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: #282828;
    display: block;
    height: 30px;
    padding: 5px 30px 5px 12px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 100;
}
.nc-dropdown.nc-dropdown-primary .p-dropdown-label {
    background: #569400;
    background: -webkit-linear-gradient(top, #569400 0%, #487c00 55%, #477a00 100%);
    background: linear-gradient(to bottom, #569400 0%, #487c00 55%, #477a00 100%);
    border-color: #4f8800 #4c8100 #2d5e00;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: subpixel-antialiased;

}

.nc-dropdown.nc-dropdown-primary .p-dropdown-label:hover {
    background: #5ea100;
    background: -webkit-linear-gradient(top, #5ea100 0%, #508a00 55%, #4f8700 100%);
    background: linear-gradient(to bottom, #5ea100 0%, #508a00 55%, #4f8700 100%);
    border-color: #579400 #4c8100 #316400;
}

.nc-dropdown.nc-dropdown-primary .p-dropdown-label:focus {
    background: #569400;
    background: -webkit-linear-gradient(top, #569400 0%, #487c00 55%, #477a00 100%);
    background: linear-gradient(to bottom, #569400 0%, #487c00 55%, #477a00 100%);
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 2px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
}

.nc-dropdown.nc-dropdown-primary .p-dropdown-label:focus:hover {
    background: #5ea100;
    background: -webkit-linear-gradient(top, #5ea100 0%, #508a00 55%, #4f8700 100%);
    background: linear-gradient(to bottom, #5ea100 0%, #508a00 55%, #4f8700 100%);
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.nc-dropdown.nc-dropdown-primary .p-dropdown-label:active, .nc-dropdown.nc-dropdown-primary .p-dropdown-label:active:hover {
    border-color: #325700 #3b6500 #427000;
    background: #406e00;
    background: -webkit-linear-gradient(top, #406e00 10%, #497d00 90%);
    background: linear-gradient(to bottom, #406e00 10%, #497d00 90%);
    box-shadow: inset 0px 2px 1px rgba(0, 0, 0, 0.12), 0 1px 0 rgba(255, 255, 255, 0.5)
}

.nc-table .ui-dropdown.ui-state-focus .ui-dropdown-label, .nc-dropdown.p-dropdown.p-focus .p-dropdown-label {
    border-color: #0089d9;
    box-shadow: 0 0 0 1px #0089d9, 0 2px 0 rgba(0, 0, 0, 0.1);
}

.nc-dropdown.p-dropdown.p-disabled .p-dropdown-label {
    background: #f5f5f5;
    border: 1px solid #c8c8c8;
    color: #a0a0a0;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-label, .nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-label {
    background: #d1d1d1;
    background: -webkit-linear-gradient(top, #cccccc 0%, #d1d1d1 100%);
    background: linear-gradient(to bottom, #cccccc 0%, #d1d1d1 100%);
    border-color: #a5a5a5 #bcbcbc #c6c6c6;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15) inset, 0 1px 0 rgba(255, 255, 255, 0.4);
    outline: none;
}

.nc-dropdown.nc-dropdown-primary.p-dropdown-open .p-dropdown-label {
    border-color: #325700 #3b6500 #427000;
    background: #406e00;
    background: -webkit-linear-gradient(top, #406e00 10%, #497d00 90%);
    background: linear-gradient(to bottom, #406e00 10%, #497d00 90%);
    box-shadow: inset 0px 2px 1px rgba(0, 0, 0, 0.12), 0 1px 0 rgba(255, 255, 255, 0.5)
}

.nc-table .ui-dropdown .ui-dropdown-trigger, .nc-dropdown.p-dropdown .p-dropdown-trigger {
    background: transparent;
    border: 0 none;
    height: 100%;
    padding: 0;
    position: absolute;
    top: 0;
    right: 9px;
    width: 9px;
    z-index: 100;
}

.nc-table .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon, .nc-dropdown.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    margin: 0;
    position: absolute;
    top: 9px;
    right: 2px;
    left: auto;
}

.nc-table .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon::before, .nc-dropdown.p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon::before {
    content: "";
    background: url(assets/images/arrow-medium.svg) -45px 0 no-repeat;
    display: block;
    height: 9px;
    width: 9px;
}

.nc-dropdown.nc-dropdown-primary .p-dropdown-trigger .p-dropdown-trigger-icon::before {
    background-position: -15px 0;
}

.nc-dropdown.p-dropdown.p-disabled .p-dropdown-trigger .p-dropdown-trigger-icon::before {
    background-position: -75px 0;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel, .nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel, .nc-dropdown-panel {
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1) #e5e5e5 #d6d6d6;
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    display: block;
    height: auto;
    min-width: 100%;
    padding: 0;
    position: absolute;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-list, .nc-dropdown-panel .p-dropdown-items {
    border-radius: 0;
    padding: 10px 0;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-items-wrapper, 
.nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel .p-dropdown-items-wrapper, .nc-dropdown-panel .p-dropdown-items-wrapper {
    overflow: auto;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-item, 
.nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel .p-dropdown-item, .nc-dropdown-panel .p-dropdown-item {
    border-radius: 0;
    padding: 0;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-item:hover, 
.nc-dropdown.ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-item.ui-state-highlight:hover, 
.nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel .p-dropdown-item:hover, 
.nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel .p-dropdown-item.p-highlight:hover, 
.nc-dropdown-panel .p-dropdown-item:hover, .nc-dropdown-panel .p-dropdown-item.p-highlight:hover {
    background: #ccebff;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-item>span, 
.nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel .p-dropdown-item>span, .nc-dropdown-panel .p-dropdown-item>span {
    display: block;
    padding: 6px 15px 7px 20px;
    line-height: 120%;
}

.nc-table .ui-dropdown.ui-dropdown-open .ui-dropdown-panel .ui-dropdown-item.ui-state-highlight, 
.nc-dropdown.p-dropdown.p-dropdown-open .p-dropdown-panel .p-dropdown-item.p-highlight, .nc-dropdown-panel .p-dropdown-item.p-highlight {
    background: transparent;
    color: #282828;
}

.ui-helper-hidden, .p-hidden {
    display: none !important;
}

.ui-helper-hidden-accessible, .p-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-helper-hidden-accessible input, .ui-helper-hidden-accessible select, .p-hidden-accessible input, .p-hidden-accessible select {
    transform: scale(0);
}

.ui-helper-reset, .p-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-helper-clearfix::before, .ui-helper-clearfix::after, .p-clearfix::before, .p-clearfix::after {
    content: "";
    display: table;
}

.ui-helper-clearfix::after, .p-clearfix::after {
    clear: both;
}

.p-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.p-dialog-mask.p-component-overlay {
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.4);
}

.p-dialog-mask.wait-dialog {
    top: 78px;
}

.p-dialog-mask.wait-dialog .revoke-dialog {
    margin-top: -39px;
}

.nc-modal {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    max-height: 90%;
    max-width: 75vw;
    overflow: hidden;
    position: relative;
    padding: 0;
    pointer-events: auto;
}

.nc-modal .p-dialog-header, .nc-modal.entity-details-dialog .p-tabview-nav-container {
    background: #f7f7f7;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 1px 1px 0 #fff inset, -1px -1px 0 #fff inset;
    /* min-height: 48px; */
    padding: 7px 20px 7px;
}

.nc-modal.entity-details-dialog .tab-navbar .p-tabview-nav-container {
    background: transparent;
    border-bottom: 0 none;
    margin-bottom: 5px;
}

.nc-modal .p-dialog-header .p-dialog-title {
    color: #282828;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.42857143;
}

.nc-modal .p-dialog-header .p-dialog-header-icons {
    float: right;
    margin-top: 6px;
}

.nc-modal .p-dialog-header .p-dialog-header-close {
    background: url('assets/images/close-icon.svg') no-repeat 0 -18px transparent;
    border: 0 none;
    display: block;
    overflow: hidden;
    padding: 0;
    height: 11px;
    width: 11px;
    text-indent: -9999px;
}

.nc-modal .p-dialog-content {
    background: #fff;
    min-height: 55px;
    overflow: hidden;
    overflow-y: auto;
    margin: 25px 0 25px 20px;
    padding: 0 20px 0 70px;
    position: relative;
}

.nc-modal .p-dialog-content:before {
    content: "";
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    background-image: url('assets/images/icons/50_informative_icons.svg');
    background-repeat: no-repeat;
    background-color: transparent;
}

.nc-modal .text-highlighted {
    font-weight: 600;
}

.nc-modal .p-dialog-footer {
    border-top: 1px solid #e5e5e5;
    padding: 11px 20px 5px 20px;
    text-align: right;
}

.nc-modal .p-dialog-footer .btn {
    margin-left: 4px;
}

.nc-modal .p-dialog-footer .btn:first-child {
    margin-left: 0;
}

.nc-modal.revoke-dialog .p-dialog-content {
    padding: 0;
    margin-right: 20px;
}

.nc-modal.remove-access-dialog .p-dialog-content {
    box-shadow: none;
    margin: 0;
    padding: 20px;
}

.nc-modal.remove-access-dialog .p-dialog-content:before, .nc-modal.revoke-dialog .p-dialog-content::before, .nc-modal.entity-details-dialog .p-dialog-content:before {
    display: none;
}

.nc-modal.nc-modal-help .p-dialog-content:before {
    background-position: -220px 0;
}

.nc-modal.nc-modal-info .p-dialog-content:before {
    background-position: -165px 0;
}

.nc-modal.nc-modal-warning .p-dialog-content:before {
    background-position: -110px 0;
}

.nc-modal.nc-modal-error .p-dialog-content:before {
    background-position: -55px 0;
}

.nc-modal.nc-modal-success .p-dialog-content:before {
    background-position: 0px 0;
}

.nc-modal .list-filter {
    max-width: 400px;
}

.nc-modal.entity-details-dialog {
    position: relative;
    min-width: 0;
    width: 1000px;
    height: 728px;
}
.nc-modal.entity-details-dialog .p-dialog-content {
    margin: 0;
    padding: 0;
}
.nc-modal.entity-details-dialog .p-tabview-nav-container {
    padding: 0;
}
.nc-modal.entity-details-dialog .p-tabview-nav-link {
    align-items: center;
}
.nc-modal.entity-details-dialog .p-tabview-nav-link>span {
    border-left: 1px solid #282828;
    margin: 10px 0;
    padding: 0 20px 0;
}
.nc-modal.entity-details-dialog .tab-navbar .p-tabview-nav-link>span {
    margin: 5px 0;
}
.nc-modal.entity-details-dialog .p-tabview-nav li:first-child .p-tabview-nav-link>span {
    border-left: 0 none;
}
.nc-modal.entity-details-dialog .tab-navbar .p-tabview-nav li:first-child .p-tabview-nav-link>span {
    padding-left: 0;
}
.nc-modal.entity-details-dialog .p-tabview-nav .p-highlight .p-tabview-nav-link {
    color: #282828;
    font-weight: bold;
}
.nc-modal.entity-details-dialog .tab-navbar .p-tabview-nav .p-highlight .p-tabview-nav-link {
    font-weight: normal;
}
.nc-modal.entity-details-dialog .tab-navbar a:focus {
    outline-offset: -1px;
}
.nc-modal.entity-details-dialog .dialog-breadcrumbs {
    font-size: 14px;
    height: 34px;
    margin: 0;
    padding: 7px 20px 5px;
}
.nc-modal.entity-details-dialog .dialog-content {
    height: 582px;
    padding: 20px 20px 0;
}
.nc-modal.entity-details-dialog .dialog-content h3 {
    border-bottom: none;
    font-size: 14px;
    margin: 5px 0;
    padding: 0;
}
.nc-modal.entity-details-dialog .nc-table tbody>tr.text-disabled, .nc-modal.entity-details-dialog .nc-table tbody>tr.text-disabled>td {
    color: #a0a0a0;
}
.nc-modal.entity-details-dialog .nc-table tbody>tr.remove>td {
    color: #D44950;
}
.nc-modal.entity-details-dialog .nc-table tbody>tr.add>td {
    color: #477A00;
}
.nc-modal.entity-details-dialog .nc-table tbody>tr.text-disabled .pull-right {
    display: none;
}
.nc-modal.entity-details-dialog .form-group .nc-dropdown.p-dropdown {
    margin-left: 0;
    width: 100%;
}
.nc-modal.entity-details-dialog .form-group.checkbox .vismaicon {
    position: relative;
    left: 4px;
    top: -4px;
}
.nc-modal.entity-details-dialog .form-group .tooltip {
    display: none;
}
.nc-modal.entity-details-dialog .form-group .tooltip.in {
    display: inline-block;
    white-space: nowrap;
}
.nc-modal.entity-details-dialog .nc-table.nc-table-condensed thead tr:not(.filters-row) th {
    padding: 9px 5px 7px;
    vertical-align: middle;
}
.nc-modal.entity-details-dialog .new-user-content {
    height: 484px;
    border: 1px solid #ddd;
    position: relative;
}
.nc-modal.entity-details-dialog .new-user-content .new-user-buttons {
    position: absolute;
    bottom: 0;
    right: 0px;
}
.nc-modal.entity-details-dialog .dialog-footer {
    border-top: 1px solid #e5e5e5;
    padding: 16px 20px 10px;
    text-align: right;
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
}
.nc-modal.entity-details-dialog .discard-mask {
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6999;
}
.nc-modal.entity-details-dialog .discard-mask .discard-dialog {
    background: #fff;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    max-height: 90%;
    transform: scale(1);
}
.nc-modal.entity-details-dialog .discard-mask .discard-dialog .modal-footer .btn + .btn {
    margin-left: 4px;
}

.nc-modal-alert .spinner-xs {
    width: 16px;
    height: 16px;
    position: relative;
    top: 0;
    right: -3px;
}

.p-button-icon-only .p-button-label {
    visibility: hidden;
    width: 0;
    flex: 0 0 auto;
    display: none;
}

.btn.move-btn {
    margin-top: 64px;
    min-width: 48px;
    width: 48px;
    position: relative;
}

.btn.move-btn>.icon-side-arrow {
    display: block;
    content: '';
    position: absolute;
    left: auto;
    top: 17px;
    right: 16px;
    height: 11px;
    width: 11px;
    background: url('assets/images/icons/arrow-side-large.svg') -103px 0 no-repeat;
}

.nc-autocomplete .p-button {
    background: #f0f0f0;
    background: linear-gradient(to bottom, #ffffff 10%, #f0f0f0 75%);
    border-width: 1px;
    border-style: solid;
    border-color: #d1d1d1 #d1d1d1 #a5a5a5;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: #282828;
}

.nc-autocomplete .p-button[disabled] {
    background: #d7d7d7;
    border: 1px solid transparent;
    opacity: 1;
    box-shadow: none;
}

.nc-autocomplete .p-autocomplete-dropdown {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    display: inline-flex;
    width: 30px;
}

.nc-autocomplete .p-autocomplete-panel {
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1) #e5e5e5 #d6d6d6;
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
}

.nc-autocomplete .p-autocomplete-items>.p-autocomplete-item>span {
    color: #282828;
    clear: both;
    display: block;
    line-height: 120%;
    padding: 6px 15px 7px 20px;
    white-space: nowrap;
}

.nc-autocomplete .p-autocomplete-items>.p-autocomplete-item>span:hover {
    background: #ccebff;
}

.nc-autocomplete .p-button-icon.caret {
    position: absolute;
    height: 10px;
    top: 50%;
    right: 10px;
    margin-top: -2px;
}

.p-checkbox {
    width: 20px;
    height: 20px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #2196F3;
    background: #2196F3;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
}

.pi {
    line-height: 1;
    display: inline-block;
}

.pi-check:before {
    content: "\e909";
}

.pi:before {
    --webkit-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.move-company-container .col-md-6 {
    padding-right: 10px;
}

.move-company-container .col-md-5-5 {
    padding-left: 10px;
}

.move-company-container .col-md-0-5 {
    padding: 0;
}

.move-company-container .move-selectors .p-autocomplete {
    display: flex;
}

.loading-text {
    display: block;
    background-color: #f1f1f1;
    min-height: 21px;
    animation: pulse 1s infinite ease-in-out;
    text-indent: -99999px;
    overflow: hidden;
}

/* new header */

.roles-overview-content.panel {
    border: 0 none;
    box-shadow: none;
}

.panel .panel-header {
    background: #f7f7f7;
    border: 1px solid #dcdcdc;
    border-bottom: 0 none;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.panel .panel-header .export-button {
    margin-left: auto;
}

.panel .panel-header>li>a {
    align-items: center;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.panel .panel-header>li.export-button>a {
    display: inline-block;
    margin-top: 5px;
    margin-right: 20px;
}

.panel .panel-header>li.active>a {
    color: #282828;
    font-weight: bold;
}

.panel .panel-header>li>a>span {
    border-left: 1px solid #282828;
    margin: 10px 0;
    padding: 0 20px 0;
}

.panel .panel-header>li:first-child>a>span {
    border-left: 0 none;
}

/* role assignment */

.panel.filters-panel .panel-heading {
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;
    padding: 8px 12px 9px;
}

.panel.filters-panel .panel-heading a {
    color: #282828;
    padding-right: 4px;
    text-decoration: none;
}

.panel.filters-panel .panel-heading a+a {
    padding-left: 4px;
    border-left: 1px solid #282828;
}

.panel.filters-panel .panel-heading .active {
    color: #0089d9;
    font-weight: bold;
}

.panel.filters-panel .panel-body {
    padding: 8px 0 0 12px;
}

.checkbox-table .search-group {
    padding-right: 12px;
}

.checkbox-table .search-group .form-control {
    padding-right: 20px;
}

.checkbox-table .search-group .form-control:focus {
    box-shadow: none;
}

.checkbox-table .search-group .close {
    position: absolute;
    top: 10px;
    right: 18px;
}

.checkbox-table .action-row a {
    display: inline-block;
    font-weight: 400;
    padding: 8px 0;
    text-decoration: none;
}

.checkbox-table .action-row a>span {
    margin-right: 4px;
    vertical-align: bottom;
}

.checkbox-table tbody label {
    margin-left: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 16px);
}

.checkbox-table tbody li.checkbox>label {
    margin-left: 14px;
}

.checkbox-table tbody label>input[type="checkbox"] {
    margin-right: 6px;
}

.checkbox-table .list-unstyled {
    margin-bottom: 5px;
}

.checkbox-table .checkbox {
    min-height: 0;
    margin-bottom: 0;
}

.checkbox-table .header-row .caret {
    margin-right: 6px;
}

.edit-row {
    display: flex;
    cursor: pointer;
}

.p-dialog-mask.move-dialog-mask {
    pointer-events: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.move-dialog {
    box-shadow: 0 4px 8px rgb(0 0 0 / 15%);
    margin-top: 0;
}

.move-dialog .p-dialog-header {
    display: none;
}

.move-dialog .modal-content {
    box-shadow: none;
}

.move-dialog .p-dialog-footer {
    background-color: #fff;
    padding: 11px 20px 5px 20px;
    border-top: 1px solid #e5e5e5;
    text-align: right;
}

.nc-tooltip {
    background-color: #fffaf2;
    border-color: #f6dba6 #fddfa7 #f1c575;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
    display: inline-block;
    min-width: 300px;
    padding: 5px 10px;
    position: absolute;
}

.nc-tooltip.p-tooltip-left {
    margin-top: 0px;
    margin-left: -9px
}

.nc-tooltip.p-tooltip-right {
    margin-top: 0px;
    margin-left: 9px
}

.nc-tooltip.p-tooltip-top {
    margin-top: -10px
}

.nc-tooltip.p-tooltip-bottom {
    margin-top: 10px
}

.nc-tooltip .p-tooltip-arrow {
    display: block;
    border-color: transparent;
    border-style: solid;
    position: absolute;
    width: 0;
    height: 0;
}

.nc-tooltip .p-tooltip-arrow:after {
    border-color: transparent;
    border-style: solid;
    content: "";
    display: block;
    margin: 0 auto;
    position: absolute;
    width: 0;
    height: 0;
}

.nc-tooltip.p-tooltip-left .p-tooltip-arrow {
    right: -7px;
    top: 50%;
    margin-top: -7px !important;
    border-width: 7px 0 7px 7px;
    border-left-color: #fddfa7
}

.nc-tooltip.p-tooltip-left .p-tooltip-arrow:after {
    top: -8px;
    left: -10px;
    border-width: 8px 0 8px 8px;
    border-left-color: #fffaf2
}

.nc-tooltip.p-tooltip-right .p-tooltip-arrow {
    left: -7px;
    top: 50%;
    margin-top: -7px !important;
    border-width: 7px 7px 7px 0px;
    border-right-color: #fddfa7
}

.nc-tooltip.p-tooltip-right .p-tooltip-arrow:after {
    top: -8px;
    right: -10px;
    border-width: 8px 8px 8px 0px;
    border-right-color: #fffaf2
}

.nc-tooltip.p-tooltip-top .p-tooltip-arrow {
    left: 50%;
    bottom: -7px !important;
    border-width: 7px 7px 0px 7px;
    border-top-color: #f1c575
}

.nc-tooltip.p-tooltip-top .p-tooltip-arrow:after {
    bottom: 2px;
    left: -8px;
    border-width: 8px 8px 0;
    border-top-color: #fffaf2
}

.nc-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    left: 50%;
    top: -7px;
    border-width: 0px 7px 7px 7px;
    border-bottom-color: #f6dba6
}

.nc-tooltip.p-tooltip-bottom .p-tooltip-arrow:after {
    top: 2px;
    left: -8px;
    border-width: 0px 8px 8px 8px;
    border-bottom-color: #fffaf2
}

.nc-tooltip .p-tooltip-text {
    background: transparent;
    color: #282828;
    font-size: 14px;
    max-width: 300px;
    padding: 0px;
    text-indent: 0px;
    text-align: center;
}

/* overview sidebar */

.panel.info-box .panel-heading {
    border-bottom: 1px solid #e6e6e6;
    font-size: 14px;
    padding: 8px 12px 9px;
}

.panel.info-box .panel-heading .panel-title {
    font-size: 14px;
    color: #282828;
}

.panel.info-box .panel-body {
    padding: 8px 0 0 12px;
}

.panel.info-box a {
    text-decoration: none;
}

.panel.info-box a:hover {
    text-decoration: underline;
}

.flex-container {
    overflow: hidden;
}

.flex-container>.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: calc(20px / 2 * -1);
}

.flex-container>.flex-wrapper-one-column {
    display: flex;
    flex-wrap: wrap;
}

.flex-sidebar {
    flex-basis: 25rem;
    flex-grow: 1;
}

.flex-sidebar>div {
    padding-left: 15px;
}

.flex-sidebar h3 {
    font-size: 14px;
    margin-bottom: 5px;
}

.flex-content {
    flex-basis: 0;
    flex-grow: 999;
    min-width: calc(60% - 20px);
}

.flex-sidebar .action-links {
    margin-bottom: 20px;
}

.flex-sidebar .action-links:first-child {
    margin-bottom: 32px;
}

.action-links li {
    margin-bottom: 4px;
}

/* .p-tabview-title {
    margin-right: 20px;
    margin-bottom: 20px;
} */

.p-autocomplete-empty-message {
    margin-left: 9px;
}

.user-access-row {
    margin-top: -24px;
}

.vismaicon.vismaicon-education:before {
    background-image: url(assets/images/icons/24_walkme.svg);
    background-position: -87px 0;
}

.navbar .nav>li.icon>a:active .vismaicon.vismaicon-menu.vismaicon-education:before {
    background-position: -115px 0;
}

.walkme-player {
    display: none !important;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    padding-top: 10px;
}

.toast, .alert.toast-alert {
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 0.8rem;
    border-color: #116fae;
    box-shadow: rgba(0, 0, 0, .08);
    color: #0d5788;
    background: #d2eafa;
    padding: 1.6rem 4rem;
    margin-bottom: 1.6rem;
    line-height: 1.3;
    max-width: 45rem;
    width: 45rem;
    display: block;
    position: absolute;
    top: calc(75% - 8px);
    left: calc(75% - 8px);
    z-index: 2001;
  }
  
  .toast:before, .alert.toast-alert:before {
    content: "";
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    position: absolute;
    top: 1.7rem;
    left: 1.6rem;
    mask: url('assets/images/icons/16_informative_icons_dynamic.svg');
    -webkit-mask: url('assets/images/icons/16_informative_icons_dynamic.svg');
    mask-position: -6.3rem 0;
    -webkit-mask-position: -6.3rem 0;
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: auto;
    -webkit-mask-size: auto;
    background-color: #1482cc;
  }
  
  .toast .vismaicon-filled, .alert.toast-alert .vismaicon-filled {
    display: none;
  }
  
  .toast .close, .alert.toast-alert .close {
    position: absolute;
    top: 1.7rem;
    right: 1.7rem;
  }
  
