/**
 * @visma/nc3-navigation-native - Visma.net Nordic Cool 3 Navigation Component Native
 * @version 1.3.8 - 2020-08-25T13:32:59.824Z
 */
/**
* Navbar - main navigation - Nordic Cool 3.0
*/

.active-on-mobile {
    display: none;
}
.navbar-collapse {
    padding-left: 0px;
    padding-right: 0px;
    background: transparent;
}
.navbar-collapse.collapse {
    position: relative;
    float: none;
    height: 42px !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
}
.navbar {
    background: #006199;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNjE5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDc5YmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(to right, #006199 0%, #0079bf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#006199', endColorstr='#0079bf', GradientType=1);
    box-shadow: none;
    border: 0px;
    border-radius: 0px;
    height: auto;
    margin-bottom: 20px;
    min-height: 40px;
    min-width: 320px;
    padding: 0;
    position: relative;
}
.navbar .navbar-brand {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 200;
    padding: 0;
    line-height: 135%;
    height: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: subpixel-antialiased;
}
.navbar .navbar-brand > .one-app {
    display: inline-block;
    padding: 6px 15px 7px;
}
.navbar .dropdown-toggle .caret {
    margin-left: 8px;
}
.navbar .navbar-brand.dropdown .dropdown-menu {
    margin-top: 0;
    left: -1px;
    min-width: 240px;
    padding: 0;
}
.navbar .navbar-brand.dropdown .dropdown-menu:before,
.navbar .navbar-brand.dropdown .dropdown-menu:after {
    display: none;
}
.navbar .navbar-brand.dropdown .dropdown-menu > li > a {
    position: relative;
    padding: 12px 15px 10px 20px;
    height: 42px;
    box-shadow: 0 1px 0 #ebebeb inset;
}
.navbar .navbar-brand.dropdown .dropdown-menu > li > a:before {
    content: '';
    display: none;
    height: 0;
    width: 0;
}
.navbar .navbar-brand.dropdown.open .dropdown-menu > li > a.active:before {
    border-color: #4d8400;
    border-style: solid;
    border-width: 0 0 0 6px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: -1px;
    width: 6px;
    z-index: 9;
}
.navbar .navbar-brand.dropdown .dropdown-menu > li > a:not(.active):hover,
.navbar .navbar-brand.dropdown .dropdown-menu > li > a.hover {
    box-shadow: 0 -1px 0 #b8def5, 0 1px 0 #b8def5 !important;
    z-index: 9;
    background: #CCEBFF;
}
.navbar .navbar-brand.dropdown .dropdown-menu > li:first-child > a {
    box-shadow: none;
}
.navbar .navbar-brand.dropdown.open {
    background: #fff;
    position: relative;
}
.navbar .navbar-brand.dropdown.open:after {
    background: #ebebeb;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjI1JSIgc3RvcC1jb2xvcj0iI2ViZWJlYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2ViZWJlYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijc1JSIgc3RvcC1jb2xvcj0iI2ViZWJlYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(to right, #ffffff 0%, #ebebeb 25%, #ebebeb 50%, #ebebeb 75%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
    content: "";
    height: 1px;
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
}
.navbar .navbar-brand.dropdown.open > .dropdown-toggle {
    color: #282828;
}
/* .navbar .nav.navbar-nav .dropdown-element.open .caret, .navbar .nav.navbar-nav .dropdown.open .caret {
    background-position: -30px center;
    transform: rotateX(0);
} */
/* .navbar .nav.navbar-nav .dropdown.user-dropdown.open .button-context .caret{
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
} */
.navbar .navbar-brand.dropdown.open .caret, .navbar .navbar-right .user-dropdown.open .dropdown-toggle .caret {
    background-position: -45px 0;
}
.navbar .navbar-brand.dropdown.open .dropdown-menu {
    border-right-width: 0;
    border-top-color: transparent;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
    max-height: calc(100vh - 84px);
    overflow-y: auto;
    width: 100%;
}
.navbar .navbar-brand.dropdown.open .dropdown-menu > li > a {
    font-size: 16px;
    font-family: 'Open Sans';
    font-weight: 200;
}
.navbar .navbar-brand.dropdown.open .dropdown-menu > li > a:focus {
    box-shadow: -2px -2px 0 #0089d9 inset,2px 2px 0 #0089d9 inset;
}
.navbar .navbar-brand.dropdown .dropdown-menu > li:first-child > a:not(.btn).active:focus {
    box-shadow: -2px -2px 0 #0089d9 inset,2px 2px 0 #0089d9 inset;
}
.navbar .navbar-brand:hover {
    background: transparent;
    color: #fff;
    box-shadow: none;
}
.navbar .navbar-brand > a:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: 1px 0 0 #3187b9;
}
.navbar .navbar-brand > a:focus {
    outline: none;
    border-right: 1px solid #fff;
    box-shadow: 1px 1px 0 #fff inset;
    color: #fff;
}
.navbar .nav {
    background: none;
    border: 0px;
    box-shadow: none;
    height: auto;
    padding: 0;
    position: inherit;
    display: block;
}
.navbar .nav > li {
    margin: 0;
    padding: 0;
    position: inherit;
    font-weight: 200;
}
.navbar .nav > li:after {
    display: none;
}
.navbar .nav > li > a {
    background: transparent;
    color: #fff;
    text-decoration: none;
    font-weight: 200;
    font-size: 18px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 0px; /* !important;*/
    border-right: 0px; /*  !important;*/
    border-bottom: 0px; /* !important;*/
    box-shadow: none;
    padding: 9px 18px 9px;
    line-height: 135%;
    height: auto;
    border-radius: 0px;
    margin-right: 0px;
    position: relative;
}
.navbar .nav > li > a:after,
.navbar .nav > li > a:before {
    display: none;
}
.navbar .nav > li > a:hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 1px 0 0 #3187b9;
    color: #fff;
    height: auto;
    padding: 9px 18px 9px;
}
.navbar .nav > li > a:focus, .navbar .nav > li.menudrop > a.dropdown-toggle:focus {
    color: #fff;
    outline: none;
    border-left: 1px solid #FFF;
    box-shadow: -1px 1px 0 #fff inset;
}
.navbar .nav > li .badge {
    border: 1px solid #fff;
    color: #fff;
    background: #C72828;
    font-size: 10px;
    font-weight: 400;
    height: 15px;
    line-height: 10px;
    min-width: 18px;
    padding: 1px 2px;
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 20;
}
.navbar .nav > li .badge.badge-primary {
    box-shadow: 1px 0 0 rgba(0, 0, 0, 0.12), -1px 0 0 rgba(0, 0, 0, 0.12), 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 1px 0 0 rgba(0, 0, 0, 0.12), -1px 0 0 rgba(0, 0, 0, 0.12), 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 0 0 rgba(0, 0, 0, 0.12), -1px 0 0 rgba(0, 0, 0, 0.12), 0 -1px 0 rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #282828;
    background: #fff;
    border: 0px;
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    height: 19px;
    line-height: 12px;
    min-width: 22px;
    text-align: center;
    text-indent: 0;
    vertical-align: sub;
}
.navbar .nav > li.icon {
    height: 42px;
    top: 0px;
    position: relative;
}
.navbar .nav > li.icon > a, .navbar .nav > li.icon > a.button-icon {
    line-height: 100%;
    padding: 0;
    width: 48px;
    height: 42px;
    overflow: hidden;
}
.navbar .nav > li.icon > a .caret {
    display: none;
}
.navbar .nav > li.icon > a .vismaicon.vismaicon-menu {
    border-left: 0;
}
.navbar .nav > li.icon > a .vismaicon.vismaicon-menu:hover {
    background: transparent;
}
.navbar .nav > li.icon > a.hover,
.navbar .nav > li.icon > a:hover,
.navbar .nav > li.icon > a.focus,
.navbar .nav > li.icon > a:focus {
    padding: 0;
}
.navbar .nav > li.icon > a.active,
.navbar .nav > li.icon > a:active {
    padding: 0;
}
.navbar .nav > li.icon > a.active .vismaicon.vismaicon-menu,
.navbar .nav > li.icon > a:active .vismaicon.vismaicon-menu {
    background-color: #fff;
    box-shadow: 1px 0px 0 rgba(0, 0, 0, 0.1), 0px 1px 0 rgba(0, 0, 0, 0.08), -1px 0px 0 rgba(0, 0, 0, 0.1), 0px -1px 0 rgba(0, 0, 0, 0.05), 0 2px 1px rgba(0, 0, 0, 0.05);
}
.navbar .nav > li.icon > a.active .vismaicon.vismaicon-menu:before,
.navbar .nav > li.icon > a:active .vismaicon.vismaicon-menu:before {
    background-position: -44px 0;
}
.navbar .nav > li.icon.open {
    box-shadow: 1px 0px 0 rgba(0, 0, 0, 0.2), 0px 1px 0 rgba(0, 0, 0, 0.08), -1px 0px 0 rgba(0, 0, 0, 0.1), 0px -1px 0 rgba(0, 0, 0, 0.05), 0 2px 1px rgba(0, 0, 0, 0.05);
}
.navbar .nav > li.icon.open > a {
    border-right: 0;
    border-left-color: transparent;
}
.navbar .nav > li.icon.active > a {
    height: 42px;
    line-height: 100%;
    padding: 0;
    width: 44px;
}
.navbar .nav > li.icon.active > a .vismaicon.vismaicon-menu {
    background-color: #fff;
    box-shadow: 1px 0px 0 rgba(0, 0, 0, 0.1), 0px 1px 0 rgba(0, 0, 0, 0.08), -1px 0px 0 rgba(0, 0, 0, 0.1), 0px -1px 0 rgba(0, 0, 0, 0.05), 0 2px 1px rgba(0, 0, 0, 0.05);
}
.navbar .nav > li.icon.active > a .vismaicon.vismaicon-menu:before {
    background-position: -44px 0;
}
.navbar .nav > li.active > a,
.navbar .nav > li.active > a:hover {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjIiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a000000', endColorstr='#33000000', GradientType=0);
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1) inset;
    color: #fff;
    height: 42px;
    padding: 9px 18px 9px;
    border-left: 1px solid #1a77ad;
}
.navbar .nav > li.active > a:focus {
    outline: none;
    box-shadow: -1px 1px 0 #fff inset;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjIiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a000000', endColorstr='#33000000', GradientType=0);
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1) inset;
    color: #fff;
}
.navbar .nav.first-level > li.active > a:focus, .navbar .nav.first-level:not(.navbar-right) > li.active:first-child  > a:focus {
    border-left: 1px solid #fff;
    box-shadow: -1px 1px 0 #fff inset;
}
.navbar .nav > li > a.dropdown-toggle {
    display: none;
}
.navbar .nav > li.dropdown > a.dropdown-toggle {
    display: inline-block;
}
.navbar .nav > li.dropdown.open > a,
.navbar .nav > li.dropdown.open > a:hover,
.navbar .nav > li.dropdown.open > a:focus {
    background: #fff;
    color: #282828;
    box-shadow: none;
}
/* .navbar .nav > li .dropdown-menu {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: transparent #e9e9e9 #dedede #eaeaea;
    background: #fff;
    margin-left: 0px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
} */
.navbar .nav > li .dropdown-menu > li > a:not(.btn) {
    border-left: 0px;
    color: #282828;
    font-size: 14px;
    line-height: 145%;
    padding: 10px 20px;
}
.navbar .nav > li .dropdown-menu > li > a:not(.btn):hover {
    background: #ccebff;
    box-shadow: none;
}
.navbar .nav > li .dropdown-menu > li > a:not(.btn):active {
    background: #f0f0f0;
    box-shadow: 0 1px 0 #e2e2e2 inset, 0 -1px 0 #e2e2e2 inset;
}
.navbar .nav > li .dropdown-menu > .divider {
    display: block;
    border-bottom: 1px solid #e6e6e6;
}
.navbar .nav > li .dropdown-menu.dropdown-menu-icon {
    padding: 20px;
    width: 270px;
    margin-right: -1px;
}
.navbar .nav > li .dropdown-menu > li.icon {
    float: left;
    width: 42px;
    height: 42px;
    margin: 0 0 10px 10px;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn) {
    padding: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    white-space: normal;
    line-height: 105%;
    width: 42px;
    height: 42px;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn):hover {
    background: #f0f9ff;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn) .vismaicon {
    margin: 0 auto;
    clear: both;
    display: block;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn):hover {
    background: #CCEBFF;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn):focus,
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn).focus {
    padding: 0;
    outline: none;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn):active,
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn).active {
    background-color: #f0f0f0;
    box-shadow: 1px 1px 0 #e2e2e2 inset, -1px -1px 0 #e2e2e2 inset;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn):active .vismaicon,
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn).active .vismaicon {
    box-shadow: none;
}
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn):active .vismaicon:active,
.navbar .nav > li .dropdown-menu > li.icon > a:not(.btn).active .vismaicon:active {
    background: transparent;
}
.navbar .nav > li .dropdown-menu.dropdown-menu-icon.dropdown-menu-icon-sm {
    width: 190px;
    padding: 20px 20px 10px;
}
.navbar .nav > li .dropdown-menu.dropdown-menu-icon.dropdown-menu-icon-sm > li.icon {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.navbar .nav > li .dropdown-menu.dropdown-menu-icon.dropdown-menu-icon-sm > li.icon:nth-child(3n+0) {
    margin-right: 0;
}
.navbar .nav > li .dropdown-menu.dropdown-menu-icon.dropdown-menu-icon-sm > li.icon > a {
    height: auto;
    padding: 0;
}
.navbar .nav.first-level {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    max-width: none;
    padding-right: 0;
    padding-left: 0px;
    position: static;
}
.navbar .nav:not(.navbar-right):not(:only-child) {
    max-width: none;
    padding-right: 0;
}
.navbar .nav.first-level:not(.navbar-right) > li:first-child > a {
    border-left: 0 none;
}
.navbar .nav.first-level .tabdrop {
    position: absolute;
    right: 0;
}
.navbar .first-level > li > a > .caret, .navbar .first-level > .menudrop.dropdown .dropdown-toggle a > .caret {
    display: none;
}
.navbar .nav.first-level > li > .dropdown-toggle > .caret {
    /* background-position: 0 0px; */
    position: relative;
    top: 2px;
}
.navbar .nav.first-level.navbar-right {
    border-right: 0 none;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 0;
    position: relative;
    margin:0;
}
.navbar .nav.navbar-nav > li.dropdown > a.dropdown-toggle > .caret {
    position: relative;
    top: 2px;
}
.navbar .navbar-text {
    color: #fff;
    padding: 0 20px;
}
.navbar .navbar-text a,
.navbar .navbar-text a:hover {
    color: #fff;
}
.navbar .navbar-btn {
    margin: 0px;
    position: relative;
    top: 6px;
    left: 18px;
}
.navbar .navbar-btn.btn-default {
    border-color: #075c8d #075888 #06527d;
}
.navbar .nav .navbar-text {
    padding: 11px 18px 10px 18px;
    margin: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

/* /.navbar */

.navbar-header {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    left: 0px;
    margin-left: 0;
    margin-right: 0;
    padding: 0px;
    top: 0px;
    z-index: 2001;
}
.navbar-header .btn.navbar-toggle {
    display: none;
    box-shadow: none;
    float: right;
    margin: 7px 20px 0px 0;
    min-width: 20px;
    padding: 3px 8px 6px;
}
.navbar-header .btn.navbar-toggle .icon-bar {
    background-color: #fff;
    border-radius: 1px;
    display: block;
    height: 2px;
    margin-top: 3px;
    width: 18px;
}
.navbar-header .btn.navbar-toggle:focus {
    box-shadow: none;
    background: #3a6400;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzNhNjQwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjYlIiBzdG9wLWNvbG9yPSIjNDA2ZTAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzQ5N2QwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: linear-gradient(to bottom, #3a6400 0%, #406e00 6%, #497d00 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3a6400', endColorstr='#497d00', GradientType=0);
    border-color: #325700 #3b6500 #427000;
    border-width: 1px;
    outline: none;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15), inset 0px 1px 0px #3a6400, inset 1px 0px 0px #447500, inset -1px 0px 0px #447500;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15), inset 0px 1px 0px #3a6400, inset 1px 0px 0px #447500, inset -1px 0px 0px #447500;
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15), inset 0px 1px 0px #3a6400, inset 1px 0px 0px #447500, inset -1px 0px 0px #447500;
}
.navbar-header p {
    margin: 0px;
    padding: 12px 0;
    color: #fff;
}

/* Second level navigation */

.navbar-second-level {
    margin-bottom: 36px;
}
.navbar-second-level .second-level {
    bottom: -37px;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid #d9d9d9;
    background: #fff;
    display: block;
    font-weight: 400;
    left: 0;
    padding: 8px 5px 8px;
    position: absolute;
    right: 0;
    visibility: visible;
    width: 100%;
    margin-bottom: 0px;
    z-index: 2000;
}
.navbar-second-level .second-level > li {
    display: inline-block;
}
.navbar-second-level .second-level > li > a {
    text-decoration: none;
    color: #282828;
    padding: 9px 15px;
}
.navbar-second-level .second-level > li > a:after,
.navbar-second-level .second-level > li > a:before {
    display: none;
}
.navbar-second-level .second-level > li > a:hover {
    color: #0089d9;
    text-decoration: underline;
}
.navbar-second-level .second-level > li.active > a {
    color: #0974b3;
    font-weight: bold;
    position: relative;
}
.navbar-second-level .second-level > li > a:focus {
    outline: 1px solid #0089d9;
    outline-offset: -2px;
}
.navbar-second-level .second-level > li > a.active:hover {
    text-decoration: none;
}
.navbar-second-level .second-level > li > a.disabled {
    color: #a0a0a0;
}
.navbar-second-level .second-level > li.dropdown-element > a:first-child {
    padding-right: 3px;
}
.navbar-second-level .second-level > li.dropdown-element > a.dropdown-toggle {
    padding-left: 0px;
    padding-right: 11px;
}
.navbar-second-level .second-level > li.dropdown-element > a.dropdown-toggle .caret {
    position: relative;
    top: 2px;
}
.navbar-second-level .second-level > li.dropdown-element.hoverState > a {
    color: #0089d9;
    text-decoration: underline;
}
.navbar-second-level .second-level > li.dropdown-element.hoverState > a .caret {
    background-position: -29px 0px;
}
.navbar-second-level .second-level > li.dropdown-element.hoverState > a.open-sublevel .caret {
    background-position: -29px 0px;
}
.navbar-second-level .second-level > li.dropdown-element.activeParent > a {
    color: #0974b3;
    font-weight: bold;
}
.navbar-second-level .second-level > li.dropdown-element.activeParent > a:hover {
    text-decoration: none;
}
.navbar-second-level .second-level > li.dropdown-element.activeParent > a .caret {
    background-position: 0 -40px;
    top: -2px;
}
.navbar-second-level .second-level > li.dropdown-element.hoverState > .open-sublevel.dropdown-toggle > .caret {
    left: 1px;
}
.navbar-second-level .second-level > li.dropdown-element.activeParent > a.open-sublevel .caret {
    top: 2px;
}
.navbar-second-level .second-level > li.dropdown-element.focusElem {
    outline: 1px dotted #282828;
}
.navbar-second-level .second-level > li.dropdown-element.focusElem > a,
.navbar-second-level .second-level > li.dropdown-element.focusElem > a:focus {
    outline: none !important;
}
.navbar-second-level .second-level > li.dropdown-element.open > .dropdown-toggle > .caret {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    background-position: -15px 0px;
    left: -1px;
    top: -2px;
}
.navbar-second-level .second-level > li.dropdown-element.open.hoverState > .dropdown-toggle .caret {
    background-position: -29px 0px;
    left: 0;
}

/* third level navigation */

.navbar-second-level .second-level .third-level:before,
.navbar-second-level .second-level .third-level:after {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    left: 40px;
    top: -7px;
    width: 0;
}
.navbar-second-level .second-level .third-level:before {
    border-bottom: 7px solid #e4e4e4;
    top: -15px;
}
.navbar-second-level .second-level .third-level:after {
    border-bottom: 7px solid #fff;
    top: -14px;
}
.navbar-second-level .first-level .second-level .third-level.dropdown-menu > li > .nav-item {
    padding: 8px 15px 8px 20px;
    position: relative;
    box-shadow: none;
    line-height: 120%;
    text-decoration: none;
}
.navbar-second-level .first-level .second-level .third-level.dropdown-menu > li > .nav-item:hover {
    background-color: #ccebff;
}
.navbar-second-level .first-level .second-level .third-level.dropdown-menu > li > .nav-item:focus {
    outline: 1px solid #0089d9;
    outline-offset: -2px;
}
.navbar-second-level .first-level .second-level .third-level.dropdown-menu > .active > .nav-item {
    color: #0974b3;
    font-weight: 700;
}
.navbar-second-level .first-level > li .second-level{
    display: none;
}
.navbar-second-level .first-level > li.active > .second-level, .navbar-second-level .first-level > li.focus > .second-level {
    display: block;
    bottom: auto;
    left: 0;
    right: auto;
    width: 100%;
}
.navbar-second-level .first-level > li.active > .second-level .dropdown, .navbar-second-level .first-level > li.focus > .second-level .dropdown {
    position: static;
}
.navbar-second-level .first-level > li.active > .second-level .dropdown.open a, .navbar-second-level .first-level > li.focus > .second-level .dropdown.open a {
    background: transparent;
}
.navbar-second-level .first-level > li.active > .second-level .dropdown a > .caret, .navbar-second-level .first-level > li.focus > .second-level .dropdown a > .caret {
    background-position: -45px 0;
}
.navbar-second-level .first-level > li.active > .second-level .dropdown a:hover > .caret, .navbar-second-level .first-level > li.focus > .second-level .dropdown a:hover > .caret {
    background-position: -30px 0;
}
.navbar-second-level .first-level > li.active > .second-level .dropdown.open a > .caret, .navbar-second-level .first-level > li.focus > .second-level .dropdown.open a > .caret {
    transform: rotateX(180deg);
}

.navbar-second-level .first-level > li.active > .second-level .dropdown.active a > .caret {
    background-position: 0 0;
}
/* Navbar right */

.navbar-right {
    margin-right: 0px;
}
.navbar-right > div {
    display: block;
    float: left;
}
.navbar-right.nav > li > a {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
}

/* Navbar toggle button */

.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: #2684b9;
    border: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    display: none;
    font-size: 18px;
    font-weight: 200;
    margin: 0px;
    padding: 12px 12px 15px;
    border-radius: 0px;
    box-shadow: 0 0px 0 #2777a5 inset, -1px 0 3px rgba(0, 0, 0, 0.15);
}
.navbar-default .navbar-toggle .icon-bar,
.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {
    display: block;
    border-top: 2px solid #fff;
    height: 2px;
    width: 22px;
    margin-top: 3px;
    border-radius: 0px;
    background: transparent;
}
/* Navbar fixed to top or bottom button */
.navbar.navbar-fixed-top,
.navbar.navbar-fixed-bottom {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1030;
}
.collapse.second-level {
    display: none;
}
.in.collapse.second-level {
    display: block;
}

/* dropdown menu */

.navbar.navbar-second-level .navbar-nav > .dropdown.menudrop {
    position: relative;
}
.navbar .dropdown.menudrop > .dropdown-toggle {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: -1px -2px 3px rgba(1, 95, 149, 0.85), 0 1px 0 #1b70a2 inset;
    width: 46px;
    height: 42px;
}
.navbar .dropdown.menudrop > .dropdown-toggle:hover {
    background: #2684b9;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzI2ODRiOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyNjg0YmIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(to right, #2684b9 0%, #2684bb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2684b9', endColorstr='#2684bb', GradientType=1);
}

.navbar .dropdown.menudrop > .dropdown-toggle > .icon-align-justify {
    background: #fff;
    border-radius: 5px;
    display: block;
    height: 5px;
    width: 5px;
    position: absolute;
    top: 20px;
    left: 19px;
}
.navbar .dropdown.menudrop > .dropdown-toggle > .icon-align-justify:before,
.navbar .dropdown.menudrop > .dropdown-toggle > .icon-align-justify:after {
    background: #fff;
    border-radius: 5px;
    content: "";
    display: block;
    height: 5px;
    width: 5px;
    position: absolute;
    top: 0;
    left: -9px;
}
.navbar .dropdown.menudrop > .dropdown-toggle > .icon-align-justify:after {
    left: 9px;
}
.navbar .dropdown.menudrop.open > .dropdown-toggle > .icon-align-justify,
.navbar .dropdown.menudrop.open > .dropdown-toggle > .icon-align-justify:before,
.navbar .dropdown.menudrop.open > .dropdown-toggle > .icon-align-justify:after {
    background-color: #646464;
}
.navbar .dropdown.menudrop > .dropdown-toggle .caret {
    display: none;
}
.navbar .dropdown.menudrop .dropdown-menu {
    background-color: #fff;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #dddddd;
    padding: 0;
    position: absolute;
    top: 42px
}
.navbar .dropdown.menudrop .dropdown-menu:after,
.navbar .dropdown.menudrop .dropdown-menu:before {
    display: none;
}
.navbar .dropdown.menudrop .dropdown-menu > li > a {
    padding: 10px 30px;
    position: relative;
}
.navbar .dropdown.menudrop.open > .dropdown-toggle {
    background: #fff;
    filter: none;
    border-left: 1px solid #014f7c;
    border-right: 1px solid #005e94 !important;
    box-shadow: -1px 0 4px rgba(1, 95, 149, 0.85), 0 -1px 0 #fff inset;
}
.navbar .dropdown.menudrop.open > .dropdown-toggle:before,
.navbar .dropdown.menudrop.open > .dropdown-toggle:after {
    border-color: #646464;
}
.navbar .nav > .dropdown.menudrop.open > .dropdown-menu > li > a {
    border-bottom: 1px solid #d9d9d9 !important;
    font-size: 18px;
    font-weight: 200;
    overflow: hidden;
    padding: 8px 30px 8px 20px;
    position: relative;
}
.navbar .nav > .dropdown.menudrop .dropdown-menu > li > a.dropdown-toggle {
    display: none;
}
.navbar-second-level .first-level li.second-level-children.is-open .second-level {
    display: block;
    padding: 0;
    position: static;
}
.navbar-second-level .first-level li.second-level-children.is-open .second-level > li {
    display: block;
}
.navbar-second-level .menudrop.open .second-level > li > a {
    display: block;
}
.navbar .first-level > .menudrop.dropdown .second-level-children > a > .caret {
    display: inline-block;
    position: absolute;
    right: 9px;
    top: 18px;
    transform: rotateX(0);
}
.navbar .first-level > .menudrop.dropdown .second-level-children.is-open > a > .caret {
    /* background-position: -60px 3px; */
    transform: rotate(180deg);
}
.navbar .first-level > .menudrop.dropdown .second-level-children.is-open .third-level.dropdown-menu {
    border: 0 none;
    box-shadow: none;
    float: none;
    position: static;
    margin-top: 0;
    width: 100%;
}
.navbar .first-level > .menudrop.dropdown .second-level-children.is-open .third-level .nav-item {
    padding-left: 30px;
}
.navbar-second-level .menudrop.dropdown .second-level > li > a:hover {
    background-color: #ccebff;
    text-decoration: none;
}
.navbar-second-level .menudrop.dropdown .second-level .dropdown.open > .nav-item > .caret {
    transform: rotateX(180deg);
}
.navbar-second-level .menudrop.dropdown .second-level .dropdown > .nav-item > .caret {
    transform: rotateX(0);
}

/* User dropdown */

.navbar .nav.navbar-nav > li.dropdown.user-dropdown {
    height: 42px;
}

.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle,
.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle:hover {
    font-size: 14px;
    line-height: 1.2;
    max-width: 300px;
    overflow: hidden;
    padding: 6px 40px 5px 46px;
    text-overflow: ellipsis;
    width: auto;
    white-space: nowrap;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle.no-context {
    height: 42px;
    line-height: 32px;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle small,
.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle:hover small {
    display: block;
    font-size: 12px;
    overflow: hidden;
    padding: 0 0 1px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle .caret,
.navbar .nav.navbar-nav > li.dropdown.user-dropdown a.dropdown-toggle:hover .caret {
    display: block;
    position: absolute;
    right: 15px;
    top: 16px;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu, .navbar .nav.navbar-nav > li.icon.dropdown .dropdown-menu {
    background: #fff;
    margin-top: 0;
    min-width: 450px;
    max-width: 600px;
    padding-top: 10px;
    position: absolute;
    right: -1px;
    left: auto;
}
.navbar .nav.navbar-nav > li.icon.custom-dropdown .dropdown-menu {
    min-width: 0;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu:before,
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu:after {
    display: none;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu > li > a:not(.btn) {
    overflow: hidden;
    padding: 5px 16px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu > li.active > a:not(.btn),
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu > li.active > a:not(.btn):hover {
    background-color: #f0f0f0;
    box-shadow: 6px 0 0 0 #4d8400 inset, 0 1px 0 #ebebeb inset;
}
.company-selection >:not(.divider) {
    padding: 10px 15px;
}
.user-details-area > div {
    display: table;
    margin-bottom: 12px;
}
.user-details-area > div > div {
    display: table-cell;
    padding-left: 10px;
    vertical-align: middle;
}
.user-details-area > div > div:not(.user-img){
    max-width: 270px;
}
.user-details-area > div > div > span {
    display: inline-block;
    line-height: 120%;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}
.user-details-area .btn {
    margin-bottom: 0;
}
.company-selection-footer .login-info,
.company-selection-footer .btn {
    margin-bottom: 0;
}
.company-selection-footer .login-info,
.company-selection-footer .login-info > span {
    line-height: 140%;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.company-selection-footer > .btn {
    display: inline-block;
    clear: none;
    margin-top: 4px;
}
.navbar .nav.navbar-nav > li.user-dropdown.icon a.dropdown-toggle,
.navbar .nav.navbar-nav > li.user-dropdown.icon a.dropdown-toggle:hover {
    font-size: 0px;
    padding-right: 24px;
    text-indent: -9999px;
    width: 44px;
}
.navbar .nav > .user-dropdown .vismaicon.vismaicon-menu {
    border-left: 0 none;
    box-shadow: none;
/*    height: 24px;*/
    left: 2px;
    position: absolute;
/*    width: 24px;*/
}
.navbar .nav > .user-dropdown .vismaicon.vismaicon-menu:before {
    height: 24px;
    width: 24px;
}
.navbar .nav > li.icon.user-dropdown > a:active .vismaicon.vismaicon-menu {
    box-shadow: none;
}
.navbar .nav > .user-dropdown .vismaicon.vismaicon-menu:hover {
    background: transparent;
}
.btn-group .vismaicon.vismaicon-menu {
    border: 0 none;
}
.user-img {
    width: 50px;
    height: 50px;
    background-image: url('images/user.svg');
}

/* context selector*/

.company-selection .input-group input[type="text"] {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .company-selection .dropdown-menu {
    margin-top: 0;
    padding-top: 0;
    min-width: 100%;
    width: auto;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .company-selection .company-input .dropdown-menu {
    width: 100%;
}

.company-selection .input-group {
    width: 100%;
}
.company-selection .input-group .company-input {
    display: table-cell;
    float: left;
    width: 100% !important;
}
.company-selection .company-input .btn.select-toggle {
    background: #fff;
    border: 1px solid #c8c8c8;
    color: #282828;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    line-height: 145%;
    margin: 0;
    padding: 4px 32px 4px 10px;
    text-align: left;
    vertical-align: middle;
    border-radius: 0;
    box-shadow: none;
    transition: none;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
}
.company-selection .search-group .company-input.dropdown, .company-selection .form-group .few-contexts .company-input.dropdown {
    position: static;
}
.navbar .nav.navbar-nav > li.dropdown.user-dropdown .company-selection .search-group .company-input .dropdown-menu {
    border-color: #c8c8c8;
    padding-bottom: 15px;
    right: 0;
}
.company-selection .company-input.dropdown.open .company-dropdown > li.focus > a {
    box-shadow: -2px -2px 0 #0089d9 inset,2px 2px 0 #0089d9 inset;
}
.company-selection .company-filter .btn {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    min-width: 0;
    padding-right: 30px;
}
.company-selection .search-group .company-filter .btn {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}
/* .company-selection .search-group .company-filter .btn.dropdown-toggle {
    background: #d1d1d1;
    background: -webkit-linear-gradient(top,#ccc 0,#d1d1d1 100%);
    background: linear-gradient(to bottom,#ccc 0,#d1d1d1 100%);
} */
.company-selection .search-group .company-filter.dropdown .btn .caret, .company-selection .form-group .few-contexts .company-filter.dropdown .btn .caret {
    background-position: -45px 0;
    position: absolute;
    top: 9px;
    right: 9px;
}
.company-selection .search-group .company-filter.dropdown:not(.open) .btn .caret, 
.company-selection .form-group .few-contexts .company-filter.dropdown:not(.open) .btn .caret {
    transform: rotateX(0);
}
.company-selection .search-group .one-context-type .nc3typeahead {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}
.company-selection .search-group .input-group .dropdown.open .nc3typeahead {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.company-selection .search-group .input-group .dropdown.open  ~ .company-filter >.btn {
    border-bottom-right-radius: 0;
}
.company-selection .form-group .company-filter .dropdown-menu > li > .active::before {
    border-color: #4d8400;
    border-style: solid;
    border-width: 0 0 0 6px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: -1px;
    width: 6px;
    z-index: 9;
}
.company-selection .search-group .search-icon {
    z-index: 1022;
}
.input-group.few-contexts > .company-input > .select-toggle .vismaicon {
   position: absolute;
   left: auto;
   right: 4px;
   top: 2px;
}
.input-group.few-contexts > .company-input > .select-toggle .vismaicon::before {
    background-position: 0 0;
}
.company-selection .input-group.few-contexts .company-dropdown > li > a {
   overflow: hidden;
   padding: 5px 32px;
   text-overflow: ellipsis;
   white-space: nowrap;
}
li.dropdown.user-dropdown .company-selection .input-group.few-contexts .company-dropdown > li.active > a:not(.btn),
li.dropdown.user-dropdown .company-selection .input-group.few-contexts .company-dropdown > li.active > a:not(.btn):hover {
    background-color: #f0f0f0;
}
li.dropdown.user-dropdown .company-selection .input-group.few-contexts .company-dropdown > li.active > a:not(.btn)::before,
li.dropdown.user-dropdown .company-selection .input-group.few-contexts .company-dropdown > li.active > a:not(.btn):hover::before {
    border-color: #4d8400;
    border-style: solid;
    border-width: 0 0 0 6px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: -1px;
    width: 6px;
    z-index: 9;
}
li.dropdown.user-dropdown .company-selection .input-group.few-contexts .company-dropdown > li.active.focus > a:not(.btn),
li.dropdown.user-dropdown .company-selection .input-group .company-filter .dropdown-menu > li > a:not(.btn):focus {
    box-shadow: -2px -2px 0 #0089d9 inset, 2px 2px 0 #0089d9 inset;
}
.company-selection .input-group.few-contexts .company-dropdown > .select-group {
    color: #0974b3;
    padding: 3px 16px;
}
.company-selection .form-group .company-input .no-results {
    background: #fff;
    border-color: transparent #e9e9e9 #dedede #eaeaea;
    border-style: solid;
    border-width: 0 1px 1px;
    box-shadow: 1px 2px 3px #0000001a;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 10px;
}
.company-selection .search-group .company-input .no-results {
    border-color: #c8c8c8; 
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

/* Responsive navbar */

@media (min-width: 768px) and (max-width: 1200px) {
    .navbar .navbar-brand,
    .navbar .navbar-brand:focus {
        min-width: 100%;
    }
}
@media (max-width: 769px) {
    body {
        min-width: 320px;
    }
    .navbar .nav.first-level.navbar-right.no-rights {
        position: static;
    }
    .navbar .navbar-right.no-rights .user-dropdown.open .company-selection {
        width: 100%;
    }
    .navbar .navbar-collapse.collapse {
        height: auto !important;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
        display: block !important;
    }
    .navbar .navbar-header {
        float: left;
    }
    .navbar.close-navigation .navbar-header .navbar-toggle {
        padding: 10px 15px 12px 12px;
    }
    .navbar.close-navigation .navbar-header .navbar-toggle .icon-bar:nth-child(2) {
        display: none;
    }
    .navbar.close-navigation .navbar-header .navbar-toggle .icon-bar:nth-child(1),
    .navbar.close-navigation .navbar-header .navbar-toggle .icon-bar:nth-child(3) {
        z-index: 100;
        transform: rotate(45deg);
    }
    .navbar.close-navigation .navbar-header .navbar-toggle .icon-bar:nth-child(1) {
        transform: rotate(45deg) translateY(1px) translateX(2px);
    }
    .navbar.close-navigation .navbar-header .navbar-toggle .icon-bar:nth-child(3) {
        transform: rotate(-45deg) translateY(-2px) translateX(2px);
    }
    .navbar .nav.first-level {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }
    .navbar .nav.first-level:not(.navbar-right) {
        float: left !important;
        max-height: 42px;
    }
    .navbar .nav.first-level.navbar-right {
        max-width: 100%;
        float: right !important;
    }
    .navbar .nav.first-level.navbar-right > li,
    .navbar .nav.first-level.navbar-right > li.icon {
        height: 42px;
    }
    .navbar .nav.first-level.navbar-right > li > a,
    .navbar .nav.first-level.navbar-right > li.icon > a,
    .navbar .nav.first-level.navbar-right > li > a.dropdown-toggle,
    .navbar .nav.first-level.navbar-right > li.icon > a.dropdown-toggle {
        border-left: 1px solid rgba(255, 255, 255, 0.15);
/*        padding: 0;*/
        min-width: 44px;
    }
    /* .navbar .nav.first-level.navbar-right > li.icon > a.dropdown-toggle,
    .navbar .nav.first-level.navbar-right > li.icon > a.dropdown-toggle:hover {
        padding: 6px 24px 6px 46px;
    } */
    .navbar .nav.first-level.navbar-right > li > a .vismaicon,
    .navbar .nav.first-level.navbar-right > li.icon > a .vismaicon,
    .navbar .nav.first-level.navbar-right > li > a.dropdown-toggle .vismaicon,
    .navbar .nav.first-level.navbar-right > li.icon > a.dropdown-toggle .vismaicon {
        /* left: 2px; */
/*        position: absolute;*/
        box-shadow: none;
    }
    .navbar .nav.first-level.navbar-right > li > a:focus,
    .navbar .nav.first-level.navbar-right > li.icon > a:focus,
    .navbar .nav.first-level.navbar-right > li > a.dropdown-toggle:focus,
    .navbar .nav.first-level.navbar-right > li.icon > a.dropdown-toggle:focus {
        box-shadow: none;
    }
    .navbar .nav.first-level.navbar-right > li > a .badge,
    .navbar .nav.first-level.navbar-right > li.icon > a .badge,
    .navbar .nav.first-level.navbar-right > li > a.dropdown-toggle .badge,
    .navbar .nav.first-level.navbar-right > li.icon > a.dropdown-toggle .badge {
        margin-left: -19px;
        right: auto;
    }
    .navbar .nav.first-level.navbar-right > .user-dropdown > .dropdown-toggle {
        padding-top: 6px;
        padding-bottom: 7px;
        font-size: 14px;
    }
    .navbar .nav.first-level.navbar-right > .dropdown.open > a {
        margin-left: 0px;
        margin-right: 0px;
    }
    .navbar .nav.first-level.navbar-right div[role="search"] .form-group {
        float: none;
        width: 100%;
    }
    .navbar .nav.first-level.navbar-right .navbar-form {
        border: 0;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        margin-right: 10px;
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset;
    }
    .navbar .nav.first-level.navbar-right .navbar-form:hover {
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset, 0 -1px 0 rgba(255, 255, 255, 0.15) inset;
    }
    .navbar .nav > li {
        float: left;
        width: auto;
    }
    .navbar .nav > li > a {
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset;
        margin: 0;
        padding: 9px 18px;
        border: 0;
    }
    .navbar .nav > li:last-child > a {
        box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.07) inset;
    }
    .navbar .nav > li > a:hover {
        box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15) inset;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 18px;
        padding-right: 18px;
    }
    .navbar .nav > li:hover + li > a {
        box-shadow: none;
    }
    .navbar .nav > li.active > a,
    .navbar .nav > li.active > a:hover {
        background: rgba(0, 0, 0, 0.18);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0.18) 50%, rgba(0, 0, 0, 0.23) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(0, 0, 0, 0.18)', endColorstr='rgba(0, 0, 0, 0.18)', GradientType=1);
        box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15), 0 1px 0 rgba(255, 255, 255, 0.15);
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 20px;
        padding-right: 20px;
        border: 0;
    }
    .navbar .nav > li.active + li > a {
        box-shadow: none;
    }
    .navbar .nav > li.active + li:hover > a {
        box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15) inset;
    }
    .navbar .nav > li > a:focus {
        border: 0;
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset;
    }
    .navbar.navbar-second-level .navbar-nav > li, .navbar .nav.navbar-right .user-dropdown.icon {
        position: static;
    }
    .touch .navbar .nav > li.icon > a.active .vismaicon.vismaicon-menu,
    .touch .navbar .nav > li.icon > a:active .vismaicon.vismaicon-menu {
        background: transparent;
    }
    .touch .navbar .nav > li.icon > a.active .vismaicon.vismaicon-menu:before,
    .touch .navbar .nav > li.icon > a:active .vismaicon.vismaicon-menu:before {
        background-position: 0 0;
    }
    .touch .navbar .nav > li .dropdown-menu > li > a:hover:not(:active):not(.btn) {
        background: transparent;
        box-shadow: none;
    }
    .navbar.navbar-second-level .third-level.dropdown-menu {
        position: absolute;
        background-color: #fff;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a.btn {
        color: #282828;
        line-height: 145%;
        padding: 4px 15px;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a.btn:hover {
        background: #dae7ef;
        background: linear-gradient(to bottom, #e8f6ff 10%, #dae7ef 75%);
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
        color: #282828;
        border-color: #becad1 #becad1 #969fa4;
    }
}

@media (pointer: coarse){
    .user-dropdown .vismaicon.vismaicon-menu.active:before, .user-dropdown .vismaicon.vismaicon-menu:hover:before {
        background-position: 0 0;
    }
    .user-dropdown.icon.open .vismaicon.vismaicon-menu.active:before, .user-dropdown.icon.open .vismaicon.vismaicon-menu:hover:before {
        background-position: -44px 0;
    }
}

/* is mobile isMobile */

.navbar.is-mobile .nav .icon.dropdown {
    position: static;
}
.navbar.is-mobile .nav > li.dropdown > a.dropdown-toggle {
    padding-left: 44px;
    width: auto;
}
.navbar.is-mobile .nav > li.icon.dropdown > a.dropdown-toggle {
    padding-left: 0;
}
.navbar.is-mobile .nav > li.icon.user-dropdown > a.dropdown-toggle {
    padding: 6px 24px 5px 46px;
}
.navbar.is-mobile .dropdown.menudrop > .dropdown-toggle > .icon-align-justify {
    display: none;
}
.navbar.is-mobile .dropdown.menudrop > .dropdown-toggle:before,
.navbar.is-mobile .dropdown.menudrop > .dropdown-toggle:after {
    border-color: #fff;
    border-style: solid;
    content: "";
    left: 11px;
    position: absolute;
    width: 22px;
    display: block;
}
.navbar.is-mobile .dropdown.menudrop > .dropdown-toggle:before {
    border-width: 2px 0;
    height: 7px;
    top: 15px;
}
.navbar.is-mobile .dropdown.menudrop > .dropdown-toggle:after {
    border-width: 0px 0 2px;
    height: 3px;
    bottom: 15px;
}
.navbar.is-mobile .dropdown.menudrop.open > .dropdown-toggle::after, .navbar.is-mobile .dropdown.menudrop.open > .dropdown-toggle::before {
    border-color: #646464;
}
.navbar.is-mobile .nav > li.dropdown > a.dropdown-toggle > span {
    line-height: inherit;
}
.navbar.is-mobile .nav.first-level.navbar-right, .navbar.is-embedded.only-one-item .nav.first-level.navbar-right {
    position: static;
}
.navbar.navbar-second-level.is-mobile .navbar-nav > .dropdown.menudrop {
    position: static;
}
.navbar.is-mobile .dropdown.menudrop .dropdown-menu {
    background-color: #fff;
    border: 0 none;
    padding: 0;
    width: 100%;
}
.navbar.is-mobile .dropdown.menudrop > .dropdown-menu:after {
    display: block;
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.navbar .nav .user-dropdown .company-selection .one-context, .navbar .nav .user-dropdown.icon .no-context ~ .company-selection .one-context {
    display: none;
}
.navbar .nav .user-dropdown.icon .company-selection .one-context {
    display: list-item;
}
.navbar.is-embedded.only-one-item .navbar-nav > .dropdown.menudrop {
    position: static;
}
.navbar.is-mobile .navbar-right .company-selection, .navbar.is-embedded.only-one-item .navbar-right .company-selection,
.navbar.is-embedded.only-one-item .dropdown.menudrop.open > .dropdown-menu {
    width: 100%;
}
.navbar.is-embedded.only-one-item .nav.navbar-nav > li.dropdown.user-dropdown .dropdown-menu {
    min-width: 0;
}
.navbar.is-mobile .nav > .menudrop li.active > a {
    background: #f0f0f0;
}
.navbar.is-mobile .nav > .menudrop li.active > a:hover {
    background: #ccebff;
}

.navbar .nav > .dropdown.menudrop.open > .dropdown-menu > li.active > a:before, .navbar.is-mobile .navbar-brand.is-open > .dropdown-menu > li.active > a:before {
    border-color: #4d8400;
    border-style: solid;
    border-width: 0 0 0 6px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: -1px;
    width: 6px;
    /*    z-index: 9;*/
}
.navbar.is-mobile .menudrop .second-level > li > a {
    display: block;
}
.navbar.is-mobile .menudrop .second-level > li > a:hover {
    color: #282828;
    text-decoration: none;
}
.navbar.is-mobile .menudrop .second-level > .active > a {
    background: #fff
}
.navbar.is-mobile .menudrop .second-level > .active > a:hover {
    color: #0974b3;
}
.navbar.is-mobile .navbar-brand {
    float: none;
}.navbar.is-mobile .navbar-brand:hover {
    box-shadow: none;
}
.navbar.is-mobile .nav > li.dropdown .dropdown-menu > .navbar-brand > a {
    border-bottom: 1px solid #d9d9d9;
    font-size: 18px;
    font-weight: 200;
    overflow: hidden;
    padding: 8px 30px 8px 36px;
    position: relative;
}
.navbar.is-mobile .nav > li.dropdown .dropdown-menu > .navbar-brand > a.dropdown-toggle {
    color: #0974b3;
    display: block;
}
.navbar.is-mobile .nav > li.dropdown .dropdown-menu > .navbar-brand > a.dropdown-toggle:focus{
    outline: none;
    outline-offset: -1px;
    box-shadow: -2px -2px 0 #0089d9 inset, 2px 2px 0 #0089d9 inset;
}
.navbar.is-mobile .nav > li.dropdown .dropdown-menu > .navbar-brand > a.dropdown-toggle.single-app {
    padding-left: 20px;
}
.navbar.is-mobile .nav > li.dropdown .dropdown-menu > .navbar-brand.is-open > a.dropdown-toggle {
    display: none !important;
}
.navbar.is-mobile .navbar-brand > a > .caret {
    display: inline-block !important;
    position: absolute;
    left: 10px;
    top: 17px;
    background-position: 0 0 !important;
    transform: rotate(90deg) !important;
}
.navbar.is-mobile .navbar-brand.is-open > a > .caret {
    background-position: -60px 3px;
    transform: rotate(180deg);
}
.navbar.is-mobile .navbar-brand.is-open > .dropdown-menu {
    display: block;
    padding: 0;
    position: static;
    max-height: calc(100vh - 42px);
    overflow-y: auto;
}
.navbar.is-mobile .nav > li.menudrop .navbar-brand.is-open > .dropdown-menu > li > a {
    box-shadow: none;
    background-color: #fff;
    border-bottom: 1px solid #d9d9d9 !important;
    font-size: 18px;
    font-weight: 200;
    overflow: hidden;
    padding: 8px 30px 8px 20px;
    position: relative;
}
.navbar.is-mobile .nav > li.menudrop .navbar-brand.is-open > .dropdown-menu > li > a:hover {
    background-color: #ccebff;
}
.navbar.is-mobile .nav > li.menudrop .navbar-brand.is-open > .dropdown-menu > li.active > a {
    background-color: #f0f0f0;
}
.navbar.is-mobile .nav > li.menudrop .navbar-brand.is-open > .dropdown-menu > li > a:focus {
    outline: none;
    box-shadow: -2px -2px 0 #0089d9 inset, 2px 2px 0 #0089d9 inset;
    background: transparent;
}
.navbar.is-mobile .nav > li.menudrop .navbar-brand.is-open > .dropdown-menu > li > a.active
.navbar.is-mobile .nav > .menudrop .second-level-children > .second-level > li.active > a {
    background: transparent;
    color: #0974b3;
}
.navbar.is-mobile .navbar-brand.is-open > .dropdown-menu > li.active > a > .caret {
    background-position: -30px 0;
    display: inline-block !important;
    position: absolute;
    left: auto;
    right: 9px;
    top: 17px;
    transform: rotate(-90deg);
}
.navbar.is-mobile .nav.navbar-nav>li.icon.dropdown .dropdown-menu, navbar.is-embedded.only-one-item .nav.navbar-nav>li.dropdown.user-dropdown .dropdown-menu {
    max-width: none;
    min-width:0;
    right:0
}
.navbar.is-mobile.navbar-second-level .third-level.dropdown-menu {
    box-shadow: none;
    margin: 0;
    position: static;
}
.navbar.is-mobile.navbar-second-level .first-level .second-level .third-level.dropdown-menu > li > .nav-item {
    padding: 8px 30px 8px 60px;
    background: transparent;
}
.navbar.is-mobile.navbar-second-level .first-level .second-level > li > a {
    padding: 8px 30px 8px 40px;
}
/* placeholder for selected in mobile mode
.navbar.is-mobile.navbar-second-level .first-level .second-level > .dropdown.open > a {
    color: #0974b3;
    font-weight: 700;
} */
.navbar.is-mobile.navbar-second-level .first-level .second-level > .dropdown > a > .caret {
    background-position: -45px center;
    transform: rotateX(0);
}
.navbar.is-mobile.navbar-second-level .first-level .second-level > .dropdown.open > a > .caret {
    background-position: 0 center;
    transform: rotateX(180deg);
}
.navbar.is-mobile .nav.first-level.navbar-right > li > a .badge, .navbar.is-mobile .nav.first-level.navbar-right > li.icon > a .badge,
.navbar.is-mobile .nav.first-level.navbar-right > li > a.dropdown-toggle .badge,
.navbar.is-mobile .nav.first-level.navbar-right > li.icon > a.dropdown-toggle .badge {
    margin-left: 3px;
    right: auto;
}

/* one app */

.navbar .navbar-brand.dropdown.open.one-app, .navbar .navbar-brand.dropdown.open.one-app:after {
    background: transparent;
    background-image: none !important;
}
.navbar .navbar-brand.dropdown.one-app:hover {
    background: none;
    box-shadow: none;
}
.navbar .navbar-brand.dropdown.one-app > .dropdown-toggle {
    color: #fff;
    cursor: auto;
}
.navbar .navbar-brand.dropdown.one-app > .dropdown-toggle > .caret {
    display: none;
}
.navbar .navbar-brand.dropdown.open.one-app .dropdown-menu {
    display: none;
}
.navbar.is-mobile .navbar-brand.one-app {
    display: none;
}

/* end one app */


/* 480 */
@media (max-width: 480px) {
    .navbar .nav.first-level.navbar-right {
        position: static;
    }
    .navbar .nav.navbar-nav>li.icon.dropdown .dropdown-menu {
        min-width: 0;
        right: 0;
        width: 100%;
    }

}

/* 768 */
@media (max-width: 768px) and (min-width: 767px) {

    .navbar-collapse.collapse.in, .navbar-collapse.collapse:not(.in) {
        display: block!important;
    }
}
/* max-width: 769px */
@media (max-width: 992px) {
    .vismaicon.vismaicon-menu {
        border: 0 !important;
    }
    .navbar div[role="search"] input {
        width: 100%;
    }
    .navbar .navbar-right div.navbar-form[role="search"]:hover .btn,
    .navbar .navbar-right div.navbar-form[role="search"] input:focus + .btn,
    .navbar .navbar-right div.navbar-form[role="search"] .btn {
        left: 2px;
        right: auto;
    }
}
/* max-width: 992px */
@media (min-width: 769px) {
    .navbar-second-level .first-level > li > .second-level,
    .navbar-second-level .first-level > li > .in.collapse.second-level {
        display: none;
    }
    .navbar .navbar-right .dropdown.tabdrop .dropdown-menu {
        right: -1px;
    }
}

.company-dropdown{
    min-width: 200px;
    height: 200px;
    overflow-y: auto;
}

.context-selector-aria-live {
    border: 0px; 
    clip: rect(0px, 0px, 0px, 0px); 
    height: 1px; 
    margin-bottom: -1px; 
    margin-right: -1px; 
    overflow: hidden; 
    padding: 0px; 
    position: absolute; 
    white-space: nowrap; 
    width: 1px;
}

.vismaicon-menu.vismaicon-feedback:before {
    background-image: url(images/24_feedback.svg);
}

.vismaicon.vismaicon-menu.vismaicon-alert:before, .vismaicon.vismaicon-menu.vismaicon-alert:hover:before {
    content: '';
    background-position: -87px 0;
}

.dropdown.open .vismaicon.vismaicon-menu.vismaicon-alert:before, 
.navbar .nav > li.icon > a.active .vismaicon.vismaicon-menu.vismaicon-alert:before, 
.navbar .nav > li.icon > a:active .vismaicon.vismaicon-menu.vismaicon-alert:before {
    background-position: -116px 0;
}

.navbar .nav > li.new-window > a:after {
    background: transparent url(images/16_export.svg) no-repeat scroll -63px 0;
    content: "";
    display: inline-block;
    height: 16px;
    margin-left: 10px;
    position: relative;
    right: 0;
    top: 0;
    width: 16px;
}

.navbar .nav .menudrop li.new-window > a:after, .navbar .nav .second-level-children li.new-window > a:after {
    background: transparent url(images/16_export.svg) no-repeat scroll 0 0;
    content: "";
    display: inline-block;
    height: 16px;
    margin-left: 10px;
    position: relative;
    right: 0;
    top: 0;
    width: 16px;
}

.navbar .ntf-in-app .ntf-overdue-tasks .vismaicon.vismaicon-sm.vismaicon-notifications::before {
    background-position: 37px 0;
}
.navbar .ntf-in-app .ntf-tasks .vismaicon.vismaicon-sm.vismaicon-notifications::before {
    background-position: 0 0;
}
.navbar .ntf-in-app .ntf-information .vismaicon.vismaicon-sm.vismaicon-notifications::before {
    background-position: 16px 0;
}
.navbar.is-mobile .nav.navbar-nav > li.icon.notify-dropdown .dropdown-menu {
    padding: 0;
}
.navbar.is-mobile .ntf-in-app.has-no-data {
    height: auto;
    max-height: none;
    min-height: 155px;
}
.navbar.is-mobile .ntf-wrapper.has-no-data::after {
    display: none;
}

::-ms-clear {
    display: none !important;
    height: 0 !important;
    width: 0 !important;
}